var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.currentEntity.templates.length == 1 &&
    _vm.currentEntity.entity_type === 'GENERAL'
  )?_c('div',[(_vm.showError)?_c('el-row',{staticClass:"template-error-body"},[_c('p',[_vm._v(_vm._s(_vm.showErrorMessage))])]):_c('el-row',[(_vm.currentTemplate == 'contacts')?_c('div',[_c('entity-contacts',{attrs:{"contactType":_vm.currentEntity._id,"accountName":_vm.entityDataId,"entityDataId":_vm.currentEntity,"hideLabel":true}})],1):(_vm.showData && _vm.getCurrentStep)?_c('entity-template-view',{attrs:{"templateData":_vm.getCurrentStep.templateInfo,"has-next":_vm.getCurrentStep.has_next,"entityDataId":_vm.entityDataId,"workflow-step-id":_vm.getCurrentStep._id,"template-data-id":_vm.getCurrentStep.templateDataId,"currentStep":_vm.getCurrentStep,"is-execute":_vm.isEdit,"layout":_vm.layout,"currentEntity":_vm.currentEntity,"isEdit":_vm.isEdit,"entityData":_vm.entityData,"currentMenu":_vm.currentMenu,"entityDataExists":_vm.entityDataExists,"printEntityData":_vm.printEntityData,"fromPopup":true,"profilePicture":_vm.getUserprofilepicture,"saveTemp":_vm.saveTemp,"openNew":_vm.openNew,"allEntitiesDataFromParent":_vm.allEntitiesDataFromParent,"allFormTemplatesFromParent":_vm.allFormTemplatesFromParent,"allCompanyUserTypesDataFromParent":_vm.allCompanyUserTypesDataFromParent},on:{"goto-next":_vm.moveToNextTemplate,"refresh-page":_vm.refreshThePage,"getDataFromTemplatesPreview":_vm.getDataFromPreviewTemplate,"closeParent":_vm.closeParent}}):_vm._e()],1)],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"all-template-container"},[_c('el-tabs',{on:{"tab-click":_vm.changeTemplate},model:{value:(_vm.currentTemplate),callback:function ($$v) {_vm.currentTemplate=$$v},expression:"currentTemplate"}},[_vm._l((_vm.allTemplates),function(template,i){return _c('el-tab-pane',{key:template._id + i,attrs:{"label":_vm.getStepsObject[template.slug].senderAlias || template.name,"name":template._id}})}),(
          _vm.currentEntity &&
          _vm.currentEntity.entity_type === 'BUSINESS' &&
          _vm.checkIsFilteredData
        )?_c('el-tab-pane',{attrs:{"name":"contacts","label":"Contacts"}}):_vm._e()],2),(_vm.showError)?_c('el-row',{staticClass:"template-error-body"},[_c('p',[_vm._v(_vm._s(_vm.showErrorMessage))])]):_c('el-row',{staticClass:"template-body"},[(_vm.currentTemplate == 'contacts')?_c('div',[_c('entity-contacts',{attrs:{"contactType":_vm.currentEntity._id,"accountName":_vm.entityDataId,"entityDataId":_vm.currentEntity,"hideLabel":true}})],1):(_vm.showData && _vm.getCurrentStep)?_c('entity-template-view',{attrs:{"templateData":_vm.getCurrentStep.templateInfo,"has-next":_vm.getCurrentStep.has_next,"entityDataId":_vm.entityDataId,"workflow-step-id":_vm.getCurrentStep._id,"template-data-id":_vm.getCurrentStep.templateDataId,"currentStep":_vm.getCurrentStep,"is-execute":_vm.isEdit,"layout":_vm.layout,"currentEntity":_vm.currentEntity,"isEdit":_vm.isEdit,"entityData":_vm.entityData,"currentMenu":_vm.currentMenu,"entityDataExists":_vm.entityDataExists,"printEntityData":_vm.printEntityData,"profilePicture":_vm.getUserprofilepicture,"saveTemp":_vm.saveTemp,"openNew":_vm.openNew,"allEntitiesDataFromParent":_vm.allEntitiesDataFromParent,"allFormTemplatesFromParent":_vm.allFormTemplatesFromParent,"allCompanyUserTypesDataFromParent":_vm.allCompanyUserTypesDataFromParent},on:{"goto-next":_vm.moveToNextTemplate,"refresh-page":_vm.refreshThePage,"getDataFromTemplatesPreview":_vm.getDataFromPreviewTemplate,"closeParent":_vm.closeParent}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }